
import Landing from '../../components/landing';
import Navbar from '../../components/navigation';
import About from '../../components/about';
import Contact from '../../components/contact';
import Categories from '../../components/category';
import Footer from '../../components/footer';
import Sponsors from '../../components/sponsors';
import Exhibitor from '../../components/exhibitor';
import EventMap from '../../components/eventMap';
import Location from '../../components/location';

function Home() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <About />
      <Categories/>
      <EventMap/>
      <Exhibitor/>
      <Location/>
      <Contact/>
      <Footer/>

    </div>
  );
}

export default Home;
