import React, { useRef, useState } from 'react';
import { FaTelegram } from "react-icons/fa";
import Input from '../input';
import Button from '../button';
import TextArea from '../textarea';
import Title from '../typography/title';
import emailjs from "emailjs-com"; // Update import
import validator from 'validator';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const name = formData.get("name");
        const email = formData.get("email");
        const message = formData.get("message");
        const phone = formData.get("phone");

        if (validator.isEmail(email) && validator.isMobilePhone(phone)) {
            setLoading(true);
            emailjs
                .sendForm(
                    "service_ccge3qd",
                    "template_69y243i",
                    form.current,
                    "wiCH6gw5ID8rO-SDq"
                )
            .then(
                (result) => {
                    alert("Message sent !");
                    form.current.reset();
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    console.log(error);
                }
            );
        } else {
            alert("Please enter a valid email and phone number!");
        }
    };

    return (

        <div id='contact' className='bg-primary text-white p-10 px-10 md:px-20'>

            <div className='flex'>
                <FaTelegram size={40} className='text-white mr-5' />
                <Title>CONTACT US</Title>
            </div>
            <h1>Need more information? Our customer service team is here to help! </h1>

            <form className="mt-5" ref={form} onSubmit={sendEmail}>
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-600"/>
                    <Input placeholder="Type your name" type="text" id="name" name="name" required className="mt-1 p-2 w-full border rounded-md" />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600"/>
                    <Input placeholder="Type your email" type="email" id="email" name="email" required className="mt-1 p-2 w-full border rounded-md" />
                </div>
                <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-600"/>
                    <Input placeholder="Type your phone" type="tel" id="phone" name="phone" required className="mt-1 p-2 w-full border rounded-md" />
                </div>
                <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-600"/>
                    <TextArea placeholder="Type your message" id="message" name="message" rows="4" required className="mt-1 p-2 w-full border rounded-md"></TextArea>
                </div>
                    <div className="flex justify-end">
                        <button type="submit" className="bg-white hover:bg-red-100 text-primary font-bold py-2 px-4 rounded">
                            Submit
                        </button>
                    </div>
            </form>
        </div>
    );
};

export default Contact;
