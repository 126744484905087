import React, { useState } from 'react'
import Navbar from '../../../components/navigation'
import Footer from '../../../components/footer'
import Input from '../../../components/input'
import LoginButton from '../../../components/loginbutton'
import Logo from "../../../assets/logo-color.png"
import firebase from '../../../firebase'
import { useNavigate } from 'react-router-dom'

const Admin = () => {

  const navigate=useNavigate();
  const [loading,setLoading]=useState(false)

  const login = async (e) => {
    e.preventDefault();
    const email = e.target.elements[0].value;
    const password = e.target.elements[1].value;
    try {
      setLoading(true)
      var result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      var uid = firebase.auth().currentUser.uid;
      if (uid == "tVWZjusidRUZ8D82USVprT3WhTm1") {
        setLoading(false)
        navigate("/Adminexhibitor");
      } else {
        alert("Invalid login !");
      }
    } catch (err) {
      if(err.code==="auth/invalid-credential"){
        setLoading(false)
        alert("Incorrect email or password !")
      }
      else{
        setLoading(false)
        console.log(err)
        alert("Something went wrong!");
      }
    }
   }
   

  return (
    <div className='min-h-[100vh] flex justify-center items-center bg-primary'>
      <div className='bg-white w-[500px] rounded-3xl flex flex-col items-center p-5'>
        <img src={Logo} className='w-[100px] my-5' />
        <form onSubmit={login}>
          <Input name='email' type='email' placeholder={"Email"} required />
          <Input name='password' type='password' placeholder={"Password"} required />
          <LoginButton type={"contained"} size={"lg"} btnType='submit' loading={loading}>Login</LoginButton>
        </form>
      </div>
    </div>
  )
}

export default Admin