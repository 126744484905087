import React, { useEffect, useState } from 'react';
import firebase from '../../firebase';
import { useNavigate } from "react-router-dom";

const Categories = ({ onSelectCategory }) => {
    const [categories, setCategories] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handleCategoryClick = (categoryName) => {
        onSelectCategory && onSelectCategory(categoryName); // Ensure onSelectCategory is defined before calling
        // Redirect to exhibitors page with selected category as query parameter
        navigate(`/exhibitor?category=${categoryName}`);
    };

    useEffect(() => {
        fetchCategories();
    }, [refresh]);
    
    const fetchCategories = async () => {
        try {
            const categoryData = [];
            const snapshot = await firebase.firestore().collection('categories').get();
            snapshot.forEach((doc) => {
                categoryData.push({ id: doc.id, ...doc.data() }); // Include document ID
            });
            setCategories([...categoryData]);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className=''>
            <h1 className='text-primary text-center text-3xl font-bold'>CATEGORIES</h1>
            <div className='flex my-2 flex-wrap justify-center'>
                {categories.map((category, index) => (
                    <Item key={index} onClick={() => handleCategoryClick(category.name)}>
                        <img src={category.icon} alt={category.name} className='w-[55px] h-[55px]' />
                        <h1>{category.name}</h1>
                    </Item>
                ))}
            </div>
        </div>
    );
}

const Item = ({ children, onClick }) => {
    return (
        <div onClick={onClick} className='mx-2 my-5 border text-center text-primary font-semibold shadow rounded border-primary w-[150px] h-[100px] flex flex-col justify-center items-center hover:bg-primary hover:text-white md:mx-10 md:w-[200px]'>
            {children}
        </div>
    );
}

export default Categories;
