import React, { useEffect } from "react";
import firebase from "../../../firebase";
import { useNavigate } from "react-router-dom";
export default function Check() {
  const navigate = useNavigate();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        if (user.uid == "tVWZjusidRUZ8D82USVprT3WhTm1") {
          console.log(user.uid);
          return;
        } else {
          firebase
            .auth()
            .signOut()
            .then(function () {
              navigate("/Adminlogin");
            });
        }
      } else {
        firebase
          .auth()
          .signOut()
          .then(function () {
            navigate("/Adminlogin");
          });
      }
    });
  }, []);

  return null;
}
