import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/navbar';
import Input from '../../../components/input';
import LoginButton from '../../../components/loginbutton';
import firebase from '../../../firebase';
import Loader from '../../../components/loading';
import { FaSpinner, FaTrash } from 'react-icons/fa'; // Removed FaEdit icon
import Check from '../check';

const AdminExhibitor = () => {
    const [exhibition, setExhibition] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [brandName, setBrandName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(''); // Changed variable name to selectedCategory
    const [boothNumber, setBoothNumber] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');
    const [categories, setCategories] = useState([]); // State for categories

    useEffect(() => {
        fetchExhibition();
        fetchCategories(); // Fetch categories when component mounts
    }, [refresh]);

    const [selectedCategories, setSelectedCategories] = useState([]);

    const fetchExhibition = async () => {
        try {
            const exhibitionData = [];
            const snapshot = await firebase.firestore().collection('exhibition').get();
            snapshot.forEach((doc) => {
                exhibitionData.push(doc.data());
            });
            setExhibition([...exhibitionData]);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchCategories = async () => {
        try {
            const categoryData = [];
            const snapshot = await firebase.firestore().collection('categories').get();
            snapshot.forEach((doc) => {
                categoryData.push({ id: doc.id, ...doc.data() }); // Include document ID
            });
            setCategories([...categoryData]);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchExhibition();
    }, [refresh]);

    const removePost = async (uid, fileUrl) => {
        if (window.confirm('Delete the item?')) {
            try {
                setRemoving(true);
                await firebase.firestore().collection('exhibition').doc(uid).delete();
                const fileRef = firebase.storage().refFromURL(fileUrl);
                await fileRef.delete();
                alert('Post removed successfully!');
                setRefresh(refresh + 1);
            } catch (err) {
                console.log(err);
            } finally {
                setRemoving(false);
            }
        }
    };

    const uploadFile = async (file, path) => {
        let date = new Date().toString();
        let name = `${file.name}_${date}`

        setUploading(true);
        if (file == null) return;

        const storageRef = firebase.storage().ref(`${path}/${name}`);
        const task = storageRef.put(file);

        task.on('state_changed', (taskSnapshot) => {
            const transferProgress = Math.round((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
            console.log(transferProgress);
        });

        try {
            await task;
            return storageRef.getDownloadURL();
        } catch (err) {
            setUploading(false);
            console.log(err);
        }
    };
    const handleCategoryChange = (e, categoryName) => {
        const isChecked = e.target.checked;
        // If checkbox is checked, add category to selectedCategories, otherwise remove it
        if (isChecked) {
            setSelectedCategories([...selectedCategories, categoryName]);
        } else {
            setSelectedCategories(selectedCategories.filter(cat => cat !== categoryName));
        }
    };

    const upload = async () => {
        if (brandName !== "" && file !== null && selectedCategories.length > 0 && boothNumber !== "") {
            const fileUrl = await uploadFile(file, 'exhibition');
            const docRef = firebase.firestore().collection("exhibition").doc();
            const docId = docRef.id;
            docRef
                .set({
                    uid: docId,
                    url: fileUrl,
                    brandName: brandName,
                    category: selectedCategories, // Change to category
                    boothNumber: boothNumber,
                    socialMedia: {
                        instagram: instagram,
                        facebook: facebook,
                        twitter: twitter,
                        youtube: youtube
                    }
                })
                .then(function () {
                    alert("file uploaded");
                    setUploading(false);
                    setRefresh(refresh + 1);
                });
        } else {
            alert("Please fill out all fields!");
            setUploading(false);
        }
    };
    
    


    return (
        <div>
            <Check />
            <AdminNavbar />
            <h1 className='flex justify-center text-3xl font-bold text-primary ml-3'>EXHIBITOR PAGE</h1>
            <hr />
            <div className='rounded-lg bg-white border-2 border-primary shadow m-10 p-5'>
                <h1>Brand Name</h1>
                <Input onChange={(e) => setBrandName(e.target.value)} placeholder="Enter brand name..." />
                <h1>Category</h1>
                    {categories.map((category) => (
                        <div key={category.id} className="flex items-center">
                            <input
                                type="checkbox"
                                id={category.id}
                                value={category.name}
                                onChange={(e) => handleCategoryChange(e, category.name)}
                                checked={selectedCategories.includes(category.name)}
                                className="form-checkbox h-4 w-4 text-blue-600"
                            />
                            <label htmlFor={category.id} className="ml-2 text-sm text-gray-700">{category.name}</label>
                        </div>
                    ))}

                <h1>Booth Number</h1>
                <Input
                    onChange={(e) => setBoothNumber(e.target.value)}
                    placeholder="Enter booth number..."
                />
                <h1>Instagram</h1>
                <Input
                    onChange={(e) => setInstagram(e.target.value)}
                    placeholder="Enter Instagram link..."
                />
                <h1>Facebook</h1>
                <Input
                    onChange={(e) => setFacebook(e.target.value)}
                    placeholder="Enter Facebook link..."
                />
                <h1>X</h1>
                <Input
                    onChange={(e) => setTwitter(e.target.value)}
                    placeholder="Enter X link..."
                />
                <h1>YouTube</h1>
                <Input
                    onChange={(e) => setYoutube(e.target.value)}
                    placeholder="Enter YouTube link..."
                />
                <h1>Logo</h1>
                <Input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                />
                  <LoginButton type={"contained"} size={"lg"} onClick={upload} disabled={loading}>
                    {uploading ? <Loader /> : <span>Upload</span>}
                </LoginButton>
            </div>
            <div className='flex m-10 flex-wrap justify-center md:justify-around'>
                {exhibition.length > 0 && exhibition.map((item) => (
                    <div key={item.uid} className='w-[400px] bg-white shadow rounded-md m-3 border-primary border-2'>
                        
                        <div className='flex justify-center items-center pt-5'>
                            <img src={item.url} alt={item.brandName} className='w-28 h-28' />
                        </div>
                        <div className='flex justify-center'>
                            <h1 className='text-primary text-xl pt-5 uppercase truncate cursor-pointer' title={item.brandName}>{item.brandName}</h1>
                        </div>
                        <div className='flex flex-col p-5'>
                            <span className='text-primary'>Category : {item.category}</span>
                            <span className='text-sm text-gray-500'>Booth Number : {item.boothNumber}</span>
                            <div className='mt-3'>
                                {item.socialMedia.instagram && <a onClick={() => window.open(`https://${item.socialMedia.instagram}`)} className='inline-block p-2 text-primary'>Instagram</a>}
                                {item.socialMedia.facebook && <a onClick={() => window.open(`https://${item.socialMedia.facebook}`)} className='inline-block p-2 text-primary'>Facebook</a>}
                                {item.socialMedia.twitter && <a onClick={() => window.open(`https://${item.socialMedia.twitter}`)} className='inline-block p-2 text-primary'>X</a>}
                                {item.socialMedia.youtube && <a onClick={() => window.open(`https://${item.socialMedia.youtube}`)} className='inline-block p-2 text-primary'>YouTube</a>}
                            </div>
                        </div>

                        <div className='flex bg-primary justify-between items-center p-5'>
                            {removing ? (
                                <FaSpinner className='text-white animate-spin' />
                            ) : (
                                <FaTrash
                                    className='text-white cursor-pointer'
                                    onClick={() => removePost(item.uid, item.url)}
                                />
                            )}
                            {/* <FaEdit
                                className='text-white cursor-pointer'
                                onClick={() => editItem(item)}
                            /> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminExhibitor;