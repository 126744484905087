import React from 'react';

const Location = () => {
  return (
    <div className="relative w-full h-96">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.963517768742!2d51.5286633154256!3d25.322333884313154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c351d3d6de07%3A0xdc0f888f643c3160!2sDoha%20Exhibition%20%26%20Convention%20Center%20(DECC)!5e0!3m2!1sen!2sqa!4v1639354182527!5m2!1sen!2sqa"
        frameBorder="0"
        style={{ border: '0', width: '100%', height: '100%' }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="Google Maps"
      ></iframe>
      <div className="absolute bottom-4 left-4 bg-primary text-white p-4 rounded-lg shadow-md">
        <h1 className="text-lg font-bold mb-2">EVENT LOCATION</h1>
        <p className="text-sm mb-2">Doha Exhibition & Convention Center (DECC)</p>
        <p className="text-sm mb-2">+97444196725</p>
        <p className="text-sm mb-2">qoutletoperations@advertiqa.com </p>
        <button className="bg-white text-primary px-4 py-2 rounded-md hover:bg-red-100" onClick={() => window.open(`https://www.google.com/maps/place/Doha+Exhibition+%26+Convention+Center+(DECC)/@25.322334,51.530852,16z/data=!4m6!3m5!1s0x3e45c351d3d6de07:0xdc0f888f643c3160!8m2!3d25.3223339!4d51.5308517!16s%2Fg%2F11cn5nc7sd?hl=en&entry=ttu`)}>Get Direction</button>
        
      </div>
    </div>
  );
};

export default Location;
