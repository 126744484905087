import React from 'react'
import { Slide, Zoom } from 'react-awesome-reveal'
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaLinkedinIn, FaTwitterSquare } from 'react-icons/fa'

const Footer = () => {
    return (
            <div className='flex justify-center items-center p-5'>
                <Zoom delay={300} className='mr-2'>
                    <a href="https://www.facebook.com/QatarOutletExhibition" target="_blank" rel="noopener noreferrer">
                        <FaFacebookSquare size={40} className='text-blue-900' />
                    </a>
                </Zoom>
                <Zoom delay={500} className='mr-2'>
                    <a href="https://www.instagram.com/qataroutletexhibition/" target="_blank" rel="noopener noreferrer">
                        <FaInstagramSquare size={40} className='text-red-900' />
                    </a>
                </Zoom>

                <Zoom delay={800} className='mr-2'>
                    <a href="https://www.linkedin.com/company/qataroutletexhibition/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin size={40} className='text-blue-900' />
                    </a>
                </Zoom>
            </div>

    )
}

export default Footer