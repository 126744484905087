import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const config = {
    apiKey: "AIzaSyDryL-pSJXX0SrNEpqmry7gRhAROdfIFwg",
    authDomain: "qoutlet-1fadf.firebaseapp.com",
    projectId: "qoutlet-1fadf",
    storageBucket: "qoutlet-1fadf.appspot.com",
    messagingSenderId: "927207160547",
    appId: "1:927207160547:web:ebead7b9cd7b0560ec63d6",
    measurementId: "G-2QC47Q0Y69"
};

firebase.initializeApp(config);

const storage = firebase.storage();

export { storage, firebase as default };