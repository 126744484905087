import { useEffect, useState } from "react";
import ColorLogo from "../../assets/logo-color.png";
import WhiteLogo from "../../assets/logo-white.png";
import Hamburger from 'hamburger-react'

export default function Navbar() {
    const [isSticky, setSticky] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // Define state variable to track mobile view

    const checkSticky = () => {
        window.scrollY > 80 ? setSticky(true) : setSticky(false);
    };

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    useEffect(() => {
        window.addEventListener('scroll', checkSticky);
        checkIsMobile(); // Call initially to set the correct state on component mount

        return () => {
            window.removeEventListener('scroll', checkSticky);
        };
    }, []);

    // Add event listener to window resize to update isMobile state
    useEffect(() => {
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <>
            <nav className={`fixed transition-all z-10 flex items-center justify-between p-1 ${isSticky ? 'bg-black top-0 left-0 w-full' : 'top-[20px] w-[80%] left-[5%]'}`}>
                <div className={`${isSticky ? 'block' : 'hidden'} md:hidden`}>
                    <Hamburger toggled={isOpen} toggle={setOpen} color={`${isSticky ? 'white' : 'black'}`} />
                </div>
                <div>
                    <a href="/" className="text-white">
                        {isSticky ?
                            <img src={WhiteLogo} className="w-[50px]" /> :
                            <img src={ColorLogo} className={`${isMobile ? 'w-[60px]' : 'w-[100px]'}`} />
                        }
                    </a>
                </div>
                <div className={`${isSticky ? 'text-white' : 'text-black'} hidden md:block`}>
                    <a href="/" className="mx-3">Home</a>
                    <a href="#about" className="mx-3">About Us</a>
                    <a href="exhibitors/" className="mx-3">Exhibitors</a>
                    <a href="#contact" className="mx-3">Contact Us</a>
                </div>
            </nav>

            <div className={`${isOpen && isSticky ? 'flex' : 'hidden'} fixed top-[50px] left-0 bg-black text-white w-[100vw] p-3 px-10 z-20 justify-between md:hidden `}>
                <a href="/" className="block pt-2">Home</a>
                <a href="#about" className="block pt-2">About Us</a>
                <a href="exhibitors/" className="block pt-2">Exhibitors</a>
                <a href="#contact" className="block pt-2">Contact Us</a>
            </div>
        </>
    );
}
