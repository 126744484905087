import React from 'react';

const Button = ({children, type}) => {
  return (
    <button type="button"
    className={`${type === 'primary' ? 'bg-primary text-white' : 'bg-white text-primary'} w-full text-2xl mt-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}>
        {children}
    </button>
  )
}

export default Button;
