import React from 'react'

const Loader = () => {
  return (
    <div>
        <div class="animate-spin rounded-full h-[25px] w-[25px] border-t-2 border-white"></div>
    </div>
  )
}

export default Loader