import React, { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { Zoom } from "react-awesome-reveal";
import Navbar from '../navigation';
import Button from '../button';
import LandingBanner from "../../assets/ban21-02.jpg";
import LandingBannerMob from "../../assets/ban21-03.jpg";
import emailjs from "@emailjs/browser";
import validator from 'validator';


const Landing = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useRef();


    const sendEmail = (e) => {
        
        e.preventDefault();
        const data = new FormData(e.target);
        const name = data.get("name");
        const email = data.get("email");
        const message = data.get("message");
        const phone = data.get("phone");

        if (validator.isEmail(email) && validator.isMobilePhone(phone)) {
            setLoading(true);
            try {
                emailjs
                    .sendForm(
                        "service_ccge3qd",
                        "template_9q99j2k",
                        form.current,
                        "wiCH6gw5ID8rO-SDq"
                    )
                    .then(
                        (result) => {
                            alert("Message sent !");
                            document.getElementById("form").reset();
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            console.log(error.text);

                        }
                    );
            } catch (err) {
                alert(err);
            }
        } else {
            alert("Please enter a valid email and phone number!")
        }

    };
    const ExhibitionDate = new Date('2024-05-28').getTime();

    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    
    return (
        <div className='bg-white bg-cover'>
                <div className="fixed bottom-0 right-0 p-4 z-10">
                    <button
                        className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => window.open('https://forms.gle/3d8BUcxvm6XatRbw6')}
                    >
                        Visitor Registration
                    </button>
                </div>
            <img src={LandingBanner} alt="Desktop Banner" class="hidden md:block w-full h-auto"/> {/* Desktop banner */}
            <img src={LandingBannerMob} alt="Mobile Banner" class="block md:hidden w-full h-auto"/> {/* Mobile banner */}

            {/* <div className="flex flex-col justify-center items-center text-black font-bold py-[150px]">

                <h1 className='text-xl lg:text-3xl'>May 28 - June 1</h1>
                <Zoom>
                <Countdown date={ExhibitionDate} className='text-5xl lg:text-7xl' />

                    <Button type={'primary'}>
                        Register Now
                    </Button>
                </Zoom>
                <h1>Qatar Outlet 2024: Where Luxury Meets Affordability</h1>
                <p>Experience unbeatable discounts on world-class brands. May 28 - June 1 at the Doha Exhibition and Convention Center</p>
            </div> */}



        </div>
    )
}

export default Landing