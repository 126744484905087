import React, { useEffect, useState, useRef } from 'react';
import firebase from '../../firebase';
import Navbar from '../../components/navigation';
import { Zoom } from 'react-awesome-reveal';
import Facebook from "../../assets/facebook.png";
import Instagram from "../../assets/instagram.png";
import YouTube from "../../assets/youtube.png";
import X from "../../assets/twitter.png";
import emailjs from "@emailjs/browser";
import validator from 'validator';
import { FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare, FaTimesCircle } from 'react-icons/fa';

const ExhibitorsAll = (props) => {
    const [exhibition, setExhibition] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const form = useRef(null);

    useEffect(() => {
        fetchExhibition();
    }, []);

    const fetchExhibition = async () => {
        try {
            const exhibitionData = [];
            const snapshot = await firebase.firestore().collection('exhibition').get();
            snapshot.forEach((doc) => {
                exhibitionData.push(doc.data());
            });
            setExhibition([...exhibitionData]);
        } catch (err) {
            console.log(err);
        }
    };
    const sendEmail = (e) => {
        
        e.preventDefault();
        const data = new FormData(e.target);
        const name = data.get("name");
        const email = data.get("email");
        const message = data.get("message");
        const phone = data.get("phone");

        if (validator.isEmail(email) && validator.isMobilePhone(phone)) {
            setLoading(true);
            try {
                emailjs
                    .sendForm(
                        "service_ccge3qd",
                        "template_9q99j2k",
                        form.current,
                        "wiCH6gw5ID8rO-SDq"
                    )
                    .then(
                        (result) => {
                            alert("Message sent !");
                            document.getElementById("form").reset();
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            console.log(error.text);

                        }
                    );
            } catch (err) {
                alert(err);
            }
        } else {
            alert("Please enter a valid email and phone number!")
        }

    };
    const ExhibitionDate = new Date('2024-05-28').getTime();

    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    return (
        <div> 
            <Navbar/>
            <div>
                <h1 className='text-primary text-center text-3xl pt-[150px] font-bold'>EXHIBITORS</h1>
            </div>

            <div className="fixed bottom-0 right-0 p-4 z-10">
                <button
                    className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={togglePopup}
                >
                    Register Now
                </button>
                {isOpen && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded shadow-lg md:w-1/3"> {/* Decreased width */}
                            <div className="flex justify-end mb-1"> {/* Decreased margin bottom */}
                                <button onClick={() => setIsOpen(false)} className="text-gray-600 hover:text-red-700">
                                    <svg className="h-6 w-6 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M14.348 5.652a.5.5 0 0 1 .708.708L10.707 10l4.349 4.35a.5.5 0 1 1-.708.708L10 10.707l-4.35 4.349a.5.5 0 0 1-.708-.708L9.293 10 4.652 5.65a.5.5 0 0 1 .708-.708L10 9.293l4.348-4.349z"/>
                                    </svg>
                                </button>
                            </div>
                            <h2 className="text-xl md:text-2xl mb-1">Register</h2> {/* Decreased margin bottom */}
                            <form className="max-w-sm mx-auto" ref={form} onSubmit={sendEmail} id="form"> {/* Decreased max width */}
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-600">Brand name:</label>
                                    <input type="text" id="name" name="name" required className="mt-1 p-1 w-full border rounded-md" />
                                </div>
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="category" className="block text-sm font-medium text-gray-600">Category:</label>
                                    <input type="text" id="category" name="category" required className="mt-1 p-1 w-full border rounded-md" />
                                </div>
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="contact" className="block text-sm font-medium text-gray-600">Contact person:</label>
                                    <input type="text" id="contact" name="contact" required className="mt-1 p-1 w-full border rounded-md" />
                                </div>
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email:</label>
                                    <input type="email" id="email" name="email" required className="mt-1 p-1 w-full border rounded-md" />
                                </div>
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="phone" className="block text-sm font-medium text-gray-600">Phone:</label>
                                    <input type="tel" id="phone" name="phone" className="mt-1 p-1 w-full border rounded-md" />
                                </div>
                                <div className="mb-1"> {/* Decreased margin bottom */}
                                    <label htmlFor="message" className="block text-sm font-medium text-gray-600">Message:</label>
                                    <textarea id="message" name="message" rows="4" className="mt-1 p-1 w-full border rounded-md"></textarea>
                                </div>
                                <div className="flex justify-end">
                                    <button type="submit" className="bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <div className='flex flex-wrap justify-center md:justify-around'>
    {exhibition.length > 0 && exhibition.map((item) => (
        <div key={item.uid} className='w-full md:w-[40%] lg:w-[25%] max-w-[400px] bg-white shadow rounded-md m-3 border-primary border-2'>
            <div className='flex justify-center p-3'>
                <img src={item.url} alt={item.brandName} className='w-[150px] h-[150px] object-contain' />
            </div>
            <div>
                <div>
                    <h1 className='text-primary text-center text-lg font-bold uppercase' title={item.brandName}>{item.brandName}</h1>
                    <div className='text-center'>
                    <span className='text-secondary'>Category: {Array.isArray(item.category) ? item.category.join(', ') : item.category}</span><br/>
                        <span className='text-black'>Booth Number: {item.boothNumber}</span>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className=''>
                        {item.socialMedia.instagram && <a onClick={() => window.open(`https://${item.socialMedia.instagram}`)} className='inline-block p-2 text-primary'>
                            <Zoom delay={300} className='mr-2'>
                            <img src={Instagram} style={{ width: '25px', height: '25px' }} alt="Instagram" />
                            </Zoom>
                        </a>}
                        {item.socialMedia.facebook && <a onClick={() => window.open(`https://${item.socialMedia.facebook}`)} className='inline-block p-2 text-primary'> 
                            <Zoom delay={300} className='mr-2'>
                            <img src={Facebook} style={{ width: '25px', height: '25px' }} alt="Facebook" />
                            </Zoom>
                        </a>}
                        {item.socialMedia.twitter && <a onClick={() => window.open(`https://${item.socialMedia.twitter}`)} className='inline-block p-2 text-primary'>
                            <Zoom delay={300} className='mr-2'>
                            <img src={X} style={{ width: '25px', height: '25px' }} alt="X" />
                            </Zoom>
                        </a>}
                        {item.socialMedia.youtube && <a onClick={() => window.open(`https://${item.socialMedia.youtube}`)} className='inline-block p-2 text-primary'>
                            <Zoom delay={300} className='mr-2'>
                            <img src={YouTube} style={{ width: '25px', height: '25px' }} alt="YouTube" />
                            </Zoom>
                        </a>}
                    </div>
                </div>
            </div>
        </div>
    ))}
</div>
        </div>
    );
};

export default ExhibitorsAll;
