import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/home';
import Login from './components/admin/login';
import AdminExhibitor from './components/admin/admin_exhibitor';
import Exhibitors_Filter from './components/exhibitor_filter';
import Exhibitors_All from './components/exhibitor_all';
import AdminCategory from './components/admin/admin_category';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/Adminlogin" element={<Login/>} />
        <Route exact path="/Adminexhibitor" element={<AdminExhibitor/>} /> 
        <Route exact path="/Admincategory" element={<AdminCategory/>} /> 
        <Route exact path="/exhibitor" element={<Exhibitors_Filter/>} /> 
        <Route exact path="/exhibitors" element={<Exhibitors_All/>} /> 
      </Routes>
    </Router>
  )
}

export default App