import { useEffect, useState } from "react";
import Logo from "../../assets/logo-color.png"
import Hamburger from 'hamburger-react'
import { useNavigate } from "react-router-dom";
import LoginButton from '../../components/loginbutton'

import firebase from '../../firebase'

export default function AdminNavbar() {

    const [isOpen, setOpen] = useState(false)
    const navigate = useNavigate();

    const logout = async () => {
        firebase
            .auth()
            .signOut()
            .then(function () {
                navigate("/Adminlogin");
            });
    }
    return (
        <>
            <nav className={`transition-all z-10 flex items-center justify-between p-10`}>
                <div className={`md:hidden`}>
                    <Hamburger toggled={isOpen} toggle={setOpen} color={`${isOpen ? 'black' : 'black'}`} />
                </div>
                <div>
                    <a href="/" className="text-white">
                        <img src={Logo} className="w-[100px]" />
                    </a>
                </div>
                <div className={`flex text-black hidden md:block`}>
                    <a href="/Adminexhibitor" className="mx-3">Exhibitor</a>
                    <a href="/Admincategory" className="mx-3">Category</a>
                    <div className="inline-block">
                        <LoginButton type={"contained"} onClick={logout}>
                            Logout
                        </LoginButton>
                    </div>
                </div>
            </nav>
            <div className={`${isOpen ? 'block' : 'hidden'} fixed top-[100px] left-0 bg-white shadow w-[100vw] p-3 px-10 z-20 justify-between md:hidden `}>
                <a href="/Adminexhibitor" className="mx-3">Exhibitor</a>
                <a href="/Admincategory" className="mx-3">Category</a>

            </div>
        </>
    );
}
