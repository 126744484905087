import React from 'react'
import Title from '../typography/title';
import EventImage from "../../assets/event.jpg";
import VisitorImage from "../../assets/visitor.jpg";
import { FaCalendarCheck, FaInfoCircle } from 'react-icons/fa';

const EventMap = () => {
    return (
        <div>
            <div className='relative bg-secondary p-10 md:p-20'>
                <img className='absolute left-0 top-0 w-full h-full opacity-20 object-cover' src={EventImage} alt='event image' />
                <div className='relative z-1'>
                    <div className='flex'>
                        <FaCalendarCheck size={35} className='text-white mr-5' />
                        <h1 className='text-[25px] md:text-xxl font-bold text-white'>Event Highlights</h1>
                    </div>
                    <p className='mt-5 text-white '>
                        <b>Kids' Area :</b><br/> Bring the whole family! Our kids' area is designed to entertain your little ones 
                        with safe and fun activities, while you indulge in our shopping experience.<br/>

                        <b>Live Entertainment :</b><br/> Enhance your shopping spree with live entertainment featuring local bands. Enjoy 
                        music, performances, and more as you shop.
                    </p>
                </div>
            </div>

            <div className='relative bg-primary p-10 md:p-20'>
                <img className='absolute left-0 top-0 w-full h-full opacity-30 object-cover' src={VisitorImage} alt='event image' />
                <div className='relative z-1'>
                    <div className='flex'>
                        <FaInfoCircle size={35} className='text-white mr-5' />
                        <h1 className='text-[25px] md:text-xxl font-bold text-white'>Visitor Information</h1>
                    </div>
                    <p className='mt-5 text-white '>
                        <b>Transportation and Parking : </b><br/>
                        Getting here is easy! We offer ample parking and are conveniently accessible by 
                        metro, making your visit to Qatar Outlet hassle-free.<br/>

                        <b>Time : </b><br/> Tuesday to Thursday & Saturday : 10am - 10pm, Friday : 03pm - 10pm <br/>
                        <b>Location : </b><br/> Doha Exhibition and Convention Center<br/>
                        <b>Tickets & Entry : </b><br/> Free admission for all visitors.<br/>
                    </p>

                </div>
            </div>
        </div>
        
    )
}

export default EventMap