import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../navbar';
import firebase from '../../../firebase';
import { FaTrash } from 'react-icons/fa';
import Check from '../check';

const Admincategory = () => {
    const [categories, setCategories] = useState([]);
    const [removing, setRemoving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryIcon, setCategoryIcon] = useState(null); // Changed to accept file
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [editedCategoryIcon, setEditedCategoryIcon] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, [refresh]);

    const fetchCategories = async () => {
        try {
            const categoryData = [];
            const snapshot = await firebase.firestore().collection('categories').get();
            snapshot.forEach((doc) => {
                categoryData.push({ id: doc.id, ...doc.data() }); // Include document ID
            });
            setCategories([...categoryData]);
        } catch (err) {
            console.log(err);
        }
    };

    const removeCategory = async (categoryId) => {
        if (window.confirm('Delete the category?')) {
            try {
                setRemoving(true);
                await firebase.firestore().collection('categories').doc(categoryId).delete();
                alert('Category removed successfully!');
                setRefresh(refresh + 1);
            } catch (err) {
                console.log(err);
            } finally {
                setRemoving(false);
            }
        }
    };

    const uploadCategory = async () => {
        if (categoryName !== "" && categoryIcon !== null) { // Check if icon is not null
            try {
                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(categoryIcon.name); // Use the file name as the reference
                await fileRef.put(categoryIcon); // Upload the file to Firebase Storage
                const iconURL = await fileRef.getDownloadURL(); // Get the download URL of the uploaded file
                await firebase.firestore().collection("categories").add({
                    name: categoryName,
                    icon: iconURL
                });
                alert("Category uploaded");
                setCategoryName('');
                setCategoryIcon(null);
                setRefresh(refresh + 1);
            } catch (err) {
                console.log(err);
            }
        } else {
            alert("Please fill out all fields!");
        }
    };

    const openEditForm = (categoryId, categoryName) => {
        setEditingCategoryId(categoryId);
        setEditedCategoryName(categoryName);
    };

    const updateCategory = async () => {
        if (editedCategoryName !== "" && editedCategoryIcon !== null) {
            try {
                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(editedCategoryIcon.name);
                await fileRef.put(editedCategoryIcon);
                const iconURL = await fileRef.getDownloadURL();
                await firebase.firestore().collection('categories').doc(editingCategoryId).update({
                    name: editedCategoryName,
                    icon: iconURL
                });
                alert('Category updated successfully!');
                setEditingCategoryId(null);
                setRefresh(refresh + 1);
            } catch (err) {
                console.log(err);
            }
        } else {
            alert("Please fill out all fields!");
        }
    };

    return (
        <div>
            <Check/>
            <AdminNavbar />
            <h1 className='flex justify-center text-3xl font-bold text-primary ml-3'>CATEGORY PAGE</h1>
            <hr />
            <div className='rounded-lg bg-white border-2 border-primary shadow m-10 p-5'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h1 className="text-lg font-semibold mb-2">Category Name</h1>
                        <input
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Enter category name..."
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-primary"
                        />
                    </div>
                    <div>
                        <h1 className="text-lg font-semibold mb-2">Category Icon</h1>
                        <input
                            type="file" // Change input type to file
                            onChange={(e) => setCategoryIcon(e.target.files[0])} // Get the file from event
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-primary"
                        />
                    </div>
                </div>
                <button onClick={uploadCategory} className="mt-4 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark focus:outline-none focus:bg-primary-dark">
                    Upload
                </button>
            </div>
            <div className='flex m-10 flex-wrap justify-center md:justify-around'>
                {categories.length > 0 && categories.map((category, index) => (
                    <div key={index} className='w-[400px] bg-white shadow rounded-md m-3 border-primary border-2'>
                        <div className='flex justify-center p-5'>
                            <h1 className='text-primary text-xl uppercase'>{category.name}</h1>
                        </div>
                        <div className='flex justify-center p-5'>
                            <img src={category.icon} alt={category.name} className='w-20 h-20' />
                        </div>
                        <div className='flex bg-primary justify-between items-center p-5'>
                            <FaTrash
                                className='text-white cursor-pointer'
                                onClick={() => removeCategory(category.id)}
                            />
                            <button
                                className='text-white cursor-pointer'
                                onClick={() => openEditForm(category.id, category.name)}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {editingCategoryId && (
                <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50'>
                    <div className='bg-white p-8 rounded-md'>
                        <h1 className='text-lg font-semibold mb-2'>Edit Category</h1>
                        <input
                            value={editedCategoryName}
                            onChange={(e) => setEditedCategoryName(e.target.value)}
                            placeholder="Enter category name..."
                            className="w-full px-3 py-2 border rounded-md mb-4 focus:outline-none focus:border-primary"
                        />
                        <input
                            type="file"
                            onChange={(e) => setEditedCategoryIcon(e.target.files[0])}
                            className="w-full px-3 py-2 border rounded-md mb-4 focus:outline-none focus:border-primary"
                        />
                        <button
                            onClick={updateCategory}
                            className="px-4 py-2 bg-primary text-white rounded-md hover:bg-red-500 focus:outline-none focus:bg-primary-dark mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={() => setEditingCategoryId(null)}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Admincategory;
