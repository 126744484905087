import React from 'react';
import Loader from '../loading';

const LoginButton = ({ children, type, size, btnType = 'button', loading = false, ...rest }) => {
  return (
    <button type={btnType}
      className={
        `${type === 'contained' ? 'bg-gradient-to-r from-primary to-primary-dark text-white' : 'bg-white border border-primary text-primary'}
       p-3 text-sm rounded-3xl flex justify-center
       ${size === 'lg' ? 'w-[100%]' : ' w-[150px]'}`
      }
      {...rest}
    >
      {loading ? <Loader /> :
        <div className='flex justify-center'>
          {children}
        </div>
      }
    </button>
  )
}

export default LoginButton;