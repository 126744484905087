import React from 'react'
import { FaInfoCircle } from "react-icons/fa";
import Image from "../../assets/about.jpg"

const About = () => {
  return (
    <div id='about' className='m-10 flex justify-between items-center justify-center flex-wrap md:m-[100px]'>
      <div className=' w-full md:w-1/2'>
        <div className='flex my-5'>
          <FaInfoCircle className='text-primary' size={40} />
          <h1 className='text-3xl font-bold text-primary ml-3'>ABOUT US</h1>
        </div>
        <p className='mr-5 text-lg md:text-xl w-full mb-5'> {/* Set text-lg for mobile view and text-xl for browser view */}
        Welcome to the First-Ever Qatar Outlet Exhibition that redefines luxury shopping. 
        From May 28, to June 1,  join us at the Doha Exhibition Convention Center for a 
        shopping extravaganza like no other. Featuring extensive collections from 
        Qatar-based department stores, and local businesses in a world where luxury meets 
        affordability. Whether you're looking for the latest fashion trends, cutting-edge 
        gadgets, or elegant home decor, the Qatar Outlet Exhibition has something special for you.
        </p>
      </div>

      <div className="relative z-2 w-full md:w-1/3 rounded-xl">
        <iframe
          width="100%"
          height="300px" 
          src="https://www.youtube.com/embed/dDQAhFt5IrU?si=dtS-3TuKFyCSnxea"
          title="YouTube Video Player"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </div>

    </div>
  )
}

export default About